import {FaEnvelope, FaPhone, FaUser} from "react-icons/fa";
import React from "react";
import {navigate} from "gatsby-link";
import HoverTapBtn from "../../components/HoverTapButton";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <form name="contact"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="field">
          <label className="label is-small" htmlFor={"email"}>Email*</label>
          <div className="control has-icons-left">
            <input className="input"
                   type="email"
                   placeholder="Podaj swój adres email"
                   name={"email"}
                   onChange={this.handleChange}
                   id={"email"}
                   required={true} />
            <span className="icon is-small is-left">
              <FaEnvelope />
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label is-small" htmlFor={"name"}>Imię i nazwisko*</label>
          <div className="control has-icons-left">
            <input className="input"
                   type="text"
                   placeholder="Podaj swoje imię i nazwisko"
                   name={"name"}
                   onChange={this.handleChange}
                   id={"name"}
                   required={true}  />
            <span className="icon is-small is-left">
              <FaUser />
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label is-small" htmlFor={"phone"}>Telefon</label>
          <div className="control has-icons-left">
            <input className="input"
                   type="tel"
                   placeholder="Podaj numer telefonu"
                   name={"phone"}
                   onChange={this.handleChange}
                   id={"phone"}
                   required={false} />
            <span className="icon is-small is-left">
              <FaPhone />
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label is-small" htmlFor={"message"}>Wiadomość*</label>
          <div className="control">
            <textarea className="textarea"
                      placeholder="Miejsce na twoją wiadomość"
                      name={"message"}
                      onChange={this.handleChange}
                      id={"message"}
                      required={true}></textarea>
          </div>
        </div>
        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button className="plain-btn" >
              <HoverTapBtn text="Wyślij" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}
